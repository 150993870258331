.chevron {
  transition: transform 1s ease;
}

.chevron.open {
  transform: rotate(90deg); /* Rotate counterclockwise 90 degrees */
}

.accordion-content {
  max-height: 0;
  overflow: scroll;
  transition: max-height 0.3s ease-in-out;
}

.accordion-content.open {
  max-height: 100vh;
  overflow-y: scroll;
}

.expandable-content {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.1s ease;
  padding: 0;
}

.expandable-content.open {
  max-height: 1000px; /* Adjust this value based on your content size */
  opacity: 1;
  visibility: visible;
  padding: 0 0;
  overflow-y: scroll;
}

.chevron {
  transition: transform 0.1s ease;
}

.chevron.open {
  transform: rotate(180deg);
}

[role="link"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[role="link"]:hover {
  cursor: pointer;
}

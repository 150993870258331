@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

@layer base {
  h1 {
    @apply font-montserrat text-2xl sm:text-4xl font-bold text-left text-primary relative z-0  my-2;
  }

  h2 {
    @apply font-montserrat text-xl sm:text-2xl font-semibold text-darkgrey my-1;
  }

  h3 {
    @apply font-montserrat text-lg sm:text-xl font-semibold text-primary my-2 text-left;
  }
  h4 {
    @apply font-montserrat text-base sm:text-lg font-normal text-primary my-2 text-left;
  }
  h5 {
    @apply font-montserrat text-sm sm:text-base font-normal text-primary my-2;
  }
  h6 {
    @apply font-montserrat text-base font-medium text-primary my-2;
  }
  p,
  label {
    @apply font-montserrat text-sm font-normal text-darkgrey relative z-0 my-auto;
  }
  li {
    @apply font-montserrat text-sm  font-normal text-darkgrey relative z-0 my-auto;
  }
  input {
    @apply font-montserrat text-primary;
  }
  a {
    @apply text-blue-600 cursor-pointer hover:scale-[1.02] transform transition-all duration-100 ease-in-out font-montserrat;
  }
  ul {
    @apply gap-3 flex flex-col mt-4;
  }
  button {
    @apply bg-white px-4 py-2 rounded-md shadow-md text-primary font-montserrat text-sm  text-left flex flex-row items-center justify-center gap-2 cursor-pointer hover:scale-[1.02] transform transition-all duration-100 ease-in-out;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Change the font, size, and color of the date text */
.react-datepicker__input-container input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333;
  border: 1px solid #ccc;
  padding: 2px;
  margin: 2px;
  border-radius: 4px;
}

/* Style the date picker's container */
.react-datepicker {
  border: 2px solid #007bff; /* Border color */
  border-radius: 8px; /* Rounded corners */
}

/* Style the header */
.react-datepicker__header {
  background-color: #007bff; /* Header background color */
  border-bottom: 1px solid #006fe6; /* Border color for the bottom of the header */
}

/* Change the colors for the navigation arrows */
.react-datepicker__navigation {
  top: 20px;
  border-color: #fff;
}

/* Hover effect for days */
.react-datepicker__day:hover {
  background-color: #e9ecef;
}

/* Highlighted day */
.react-datepicker__day--keyboard-selected {
  background-color: #007bff; /* Background color for the selected day */
  color: #fff; /* Text color for the selected day */
}

/* Month and year dropdown */
.react-datepicker__month-year-dropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
}

/* Adjust the dropdown container */
.react-datepicker__month-year-dropdown-container {
  padding: 10px;
}
